import React from 'react';
import clsx from 'clsx';
import { useDrag } from 'react-dnd';
import PropTypes from 'prop-types';
import { mergeRefs } from 'utils/modalHelpers';

import './DNDModal.css';

const DNDModal = ({
  id,
  left,
  top,
  title,
  width = null,
  height = null,
  children,
  outsideClickRef = null,
  labelText,
  labelActive,
  labelDescription,
}) => {
  const [{ isDragging }, drag, preview] = useDrag({
    item: { id, left, top, type: 'modal' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  if (isDragging) {
    return <div ref={drag} />;
  }

  return (
    <div
      style={{ left, top, height: height || 'auto', width: width || 'auto' }}
      className="dndmodal"
      ref={mergeRefs(outsideClickRef, preview)}
    >
      <div className="dndmodal__heading" ref={drag}>
        <span className="dndmodal__title">{title}</span>
        {labelText && (
          <div className={clsx('dndmodal__label', { dndmodal__label_active: labelActive })} title={labelDescription}>
            {labelText}
          </div>
        )}
      </div>
      <div className="dndmodal__body">{children}</div>
    </div>
  );
};

DNDModal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  left: PropTypes.number,
  top: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.node,
  outsideClickRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  labelText: PropTypes.string,
  labelActive: PropTypes.bool,
  labelDescription: PropTypes.string,
};

export default DNDModal;
