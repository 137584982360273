import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SelectSearch from 'react-select-search';

import './CustomSelect.css';

/**
 * @typedef Option - Select options format
 * @param {string} name - Displayed name
 * @param {string} value - Saved value
 */

/**
 * @param {Option[]} options - Custom select options
 */
const CustomSelect = ({ className, error, options, wrapperStyle, ...props }) => {
  return (
    <div className={clsx('custom-select__wrap', className)} style={wrapperStyle}>
      <SelectSearch className={clsx('custom-select')} options={options} {...props} />
      {error && <span className="custom-select__error">{error}</span>}
    </div>
  );
};

CustomSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  error: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  wrapperStyle: PropTypes.shape({
    width: PropTypes.number,
    maxWidth: PropTypes.number,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

export default CustomSelect;
