import { createSlice } from '@reduxjs/toolkit';
import { MODALS } from 'utils/globalConstants';

const initialState = {
  currentSidebarModal: null,
  alarmsModalOpen: false,
  alarmsModalPosition: {
    top: 0,
    left: 0,
  },
  flightsModalPosition: {
    top: 0,
    left: 0,
  },
  filtersModalPosition: {
    top: 0,
    left: 0,
  },
  mapLayersModalPosition: {
    top: 0,
    left: 0,
  },
  zoomPresetModalPosition: {
    top: 0,
    left: 0,
  },
};

export const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openNavbarModal(state, action) {
      state.currentSidebarModal = action.payload;
    },
    closeNavbarModal(state) {
      state.currentSidebarModal = null;
    },
    setAlarmsModalOpen(state, action) {
      state.alarmsModalOpen = action.payload;
    },
    setModalPosition(state, action) {
      switch (action.payload.type) {
        case MODALS.ALARMS:
          state.alarmsModalPosition = action.payload.position;
          break;
        case MODALS.MAP_LAYERS:
          state.mapLayersModalPosition = action.payload.position;
          break;
        case MODALS.ZOOM_PRESET:
          state.zoomPresetModalPosition = action.payload.position;
          break;
        case MODALS.FLIGHTS:
          state.flightsModalPosition = action.payload.position;
          break;
        case MODALS.FILTERS:
          state.filtersModalPosition = action.payload.position;
          break;
        default:
          console.error('Wrong id');
          break;
      }
    },
  },
});

export default modalSlice.reducer;

export const { openNavbarModal, closeNavbarModal, setAlarmsModalOpen, setModalPosition } = modalSlice.actions;
