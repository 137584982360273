import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { setModalPosition } from 'redux/modules/modal';
import { MAP_CONTROLS } from 'utils/globalConstants';
import SideNavigation from 'components/common/SideNavigation';
import PlaneBackground from 'components/base/PlaneBackground';
import FullPageLoader from 'components/base/FullPageLoader';
import Header from 'components/common/Header';
import AlarmsModal from 'components/common/AlarmsModal';
import FlightsModal from 'components/common/MapControls/FlightsModal';
import MapLayersModal from 'components/common/MapControls/MapLayersModal';
import ZoomPresetsModal from 'components/common/MapControls/ZoomPresetsModal';
import FiltersModal from 'components/common/MapControls/FiltersModal';
import DNDWrapper from './DNDWrapper';

import './Page.css';

const Page = ({
  children,
  className,
  showHeader = true,
  showPlane = false,
  showSidebar = true,
  showBottomBar = false,
  dndActive = true,
  hiddenSidebarButton = false,
  fullscreen,
}) => {
  const dispatch = useDispatch();
  const {
    alarmsModalPosition,
    alarmsModalOpen,
    mapLayersModalPosition,
    zoomPresetModalPosition,
    flightsModalPosition,
    filtersModalPosition,
  } = useSelector((state) => state.modal);
  const { controls } = useSelector((state) => state.map);

  const moveBox = (id, left, top) => {
    dispatch(
      setModalPosition({
        type: id,
        position: {
          left,
          top,
        },
      })
    );
  };

  const renderSideNavigation = () => {
    return showSidebar ? (
      <SideNavigation hiddenSidebarButton={hiddenSidebarButton} showBottomBar={showBottomBar}>
        <Suspense fallback={<FullPageLoader />}>{children}</Suspense>
      </SideNavigation>
    ) : (
      <Suspense fallback={<FullPageLoader />}>{children}</Suspense>
    );
  };

  return (
    <div className={clsx('page', className, { page_fullscreen: fullscreen })}>
      {showHeader && <Header />}
      {dndActive ? (
        <DNDWrapper moveBox={moveBox}>
          {renderSideNavigation()}
          {alarmsModalOpen && <AlarmsModal left={alarmsModalPosition.left} top={alarmsModalPosition.top} />}
          {controls[MAP_CONTROLS.FLIGHTS] && (
            <FlightsModal left={flightsModalPosition.left} top={flightsModalPosition.top} />
          )}
          {controls[MAP_CONTROLS.MAP_LAYERS] && (
            <MapLayersModal left={mapLayersModalPosition.left} top={mapLayersModalPosition.top} />
          )}
          {controls[MAP_CONTROLS.FILTERS] && (
            <FiltersModal left={filtersModalPosition.left} top={filtersModalPosition.top} />
          )}
          {controls[MAP_CONTROLS.ZOOM_PRESET] && (
            <ZoomPresetsModal left={zoomPresetModalPosition.left} top={zoomPresetModalPosition.top} />
          )}
        </DNDWrapper>
      ) : (
        renderSideNavigation()
      )}

      {showPlane && <PlaneBackground />}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  showHeader: PropTypes.bool,
  showPlane: PropTypes.bool,
  showSidebar: PropTypes.bool,
  dndActive: PropTypes.bool,
  fullscreen: PropTypes.bool,
  hiddenSidebarButton: PropTypes.bool,
};

export default Page;
