import { combineReducers } from 'redux';
import mapReducer from './map';
import modalReducer from './modal';
import authReducer from './auth';
import usersReducer from './users';
import usersLoginsReducer from './usersLogins';
import settingsReducer from './settings';
import geofenceReducer from './geofence';
import customersReducer from './customers';
import activityReducer from './activity';
import aircraftsReducer from './aircrafts';
import operationsReducer from './operations';
import notificationsReducer from './notifications';
import reportReducer from './report';
import tablesReducer from './tables';
import externalDBReducer from './externalDB';
import sensorsReducer from './sensors';
import scheduledFlightsReducers from './scheduledFlights';
import statisticReducer from './statistic';

const rootReducer = combineReducers({
  map: mapReducer,
  modal: modalReducer,
  auth: authReducer,
  users: usersReducer,
  usersLogins: usersLoginsReducer,
  settings: settingsReducer,
  geofence: geofenceReducer,
  customers: customersReducer,
  activity: activityReducer,
  aircrafts: aircraftsReducer,
  operation: operationsReducer,
  notifications: notificationsReducer,
  report: reportReducer,
  tables: tablesReducer,
  externalDB: externalDBReducer,
  sensors: sensorsReducer,
  scheduledFlights: scheduledFlightsReducers,
  statistic: statisticReducer,
});

export default rootReducer;
