import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

/**
 * @typedef {Object} Operation
 * @property {number} id
 * @property {Date} createdAt
 * @property {string} flight
 * @property {string} registration
 * @property {string} icao
 * @property {string} eventName
 * @property {string} type
 * @property {string} faaCategory
 * @property {string} category
 * @property {string} operatorDetails
 */

/**
 * @typedef {Object} OperationsState
 * @property {Operation[]} operations - list of operations
 * @property {boolean} fetching - is fetching list of operations
 * @property {Object} meta - meta information about total count of operations
 * @property {Object} aircraftIdMap - Object with fetching flag and list of operations by aircraft ID saved by key
 * for example aircraftIdMap: {
 *   102342: Operation[],
 *   fetching: false
 * }
 *
 */
const initialState = {
  fetching: true,
  operations: [],
  meta: { today: null, yesterday: null, month: null, year: null, lastYear: null },
  aircraftIdMap: {
    fetching: true,
  },
  dataFetched: false,
  fetchAircraftRequestId: '',
  total: 0,
};

export const fetchOperations = createAsyncThunk('operations/fetchAll', async (paginationParams) => {
  return api.getOperations(paginationParams);
});

export const fetchOperationsById = createAsyncThunk('operations/fetchById', async (id) => {
  return api.getOperationsById(id);
});

export const deleteOperations = createAsyncThunk('operations/delete', async (items) => {
  return api.deleteOperations(items);
});

const handlePendingAction = (state) => {
  state.fetching = true;
};

const handleRejectedAction = (state) => {
  state.fetching = false;
};

export const operationsSlice = createSlice({
  name: 'operation',
  initialState,
  extraReducers: {
    [fetchOperations.pending]: (state, action) => {
      state.fetching = true;
      state.fetchAircraftRequestId = action.meta.requestId;
    },
    [fetchOperationsById.pending]: handlePendingAction,

    [fetchOperations.fulfilled]: (state, action) => {
      if (action.meta.requestId !== state.fetchAircraftRequestId) {
        return;
      }
      state.operations = action.payload?.data;
      state.total = action.payload?.total;
      state.fetching = false;
    },
    [fetchOperationsById.fulfilled]: (state, action) => {
      const { meta = {}, payload } = action;
      const { arg = 'unknown' } = meta;
      state.aircraftIdMap[arg] = payload;
      state.fetching = false;
    },
    [deleteOperations.fulfilled]: (state, { payload }) => {
      state.operations = state.operations.filter((g) => {
        return payload.findIndex((e) => e === g.id) < 0;
      });
    },

    [fetchOperations.rejected]: handleRejectedAction,
    [fetchOperationsById.rejected]: handleRejectedAction,
  },
  reducers: {
    setFetching(state, action) {
      state.fetching = action.payload;
    },
    setDataFetched(state) {
      state.dataFetched = true;
    },
    addOperation(state, action) {
      state.operations = [action.payload, ...state.operations];
    },
    incrementOperationMeta(state) {
      if (typeof state.meta?.today === 'number') {
        state.meta.today += 1;
        state.meta.month += 1;
        state.meta.year += 1;
      }
    },
    updateOperationsMeta(state, action) {
      state.meta = action.payload;
    },
  },
});

export default operationsSlice.reducer;
export const { setDataFetched, setFetching, addOperation, updateOperationsMeta, incrementOperationMeta } =
  operationsSlice.actions;
