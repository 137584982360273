import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MenuButton from '../MenuButton';

import './SideModal.css';

const SideModal = ({ children, isOpen = false, className, onRequestClose, coverContent }) => {
  const container = document.getElementById('sidebar-modal');

  return container && isOpen
    ? ReactDOM.createPortal(
        <div className={clsx('sidebar-modal', className, { 'sidebar-modal_content': coverContent })}>
          <MenuButton className="sidebar-modal_menu-button" isCloseButton onClick={onRequestClose} />
          {children}
        </div>,
        container
      )
    : null;
};

SideModal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  coverContent: PropTypes.bool,
};

export default SideModal;
