/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './CustomInput.css';

const CustomInput = ({
  className,
  textarea,
  measureUnit,
  error,
  login,
  iconType,
  monospace,
  minHeight,
  condensed,
  ...props
}) => {
  const getClasses = () =>
    clsx('input', {
      input_textarea: textarea,
      input_monospace: monospace,
      input_measureUnit: measureUnit,
      input_icon: iconType,
      input_condensed: condensed,
      [`input_icon-type_${iconType}`]: iconType,
    });

  const getInput = () => {
    if (textarea) return <textarea {...props} className={getClasses()} style={{ minHeight }} />;
    return <input {...props} className={getClasses()} />;
  };

  return (
    <div className={clsx('input__wrap', className)}>
      {getInput()}
      <span className="input__icon">{!iconType && measureUnit}</span>
      {!login && error && <span className="input__error">{error}</span>}
    </div>
  );
};

CustomInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  form: PropTypes.shape({
    touched: PropTypes.objectOf(PropTypes.bool),
    errors: PropTypes.objectOf(PropTypes.string),
  }),
  error: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  textarea: PropTypes.bool,
  measureUnit: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'email', 'image', 'number', 'date', 'time']),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  step: PropTypes.number,
  disabled: PropTypes.bool,
  login: PropTypes.bool,
  iconType: PropTypes.oneOf(['search']),
  readonly: PropTypes.bool,
  pattern: PropTypes.string,
  monospace: PropTypes.bool,
  minHeight: PropTypes.number,
  condensed: PropTypes.bool,
};

export default CustomInput;
