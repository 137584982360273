import React, { lazy } from 'react';
import Page from 'components/common/Page';
const ExternalDBBasestation = lazy(() => import('./ExternalDBBasestation'));

const ExternalDBBasestationPage = () => {
  return (
    <Page>
      <ExternalDBBasestation />
    </Page>
  );
};

export default ExternalDBBasestationPage;
