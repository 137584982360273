// prettier-ignore
export const aircraftListTupleToObject = (tuple) => {
  const [id, registration, pickedUpFlight, type, faaCategory, aircraftDesignGroup, operatorDetails, basedIcaoList, isArchived, iconType] = tuple;
  return {id, registration, pickedUpFlight, type, faaCategory, aircraftDesignGroup, operatorDetails, basedIcaoList, isArchived, iconType};
}

// prettier-ignore
export const aircraftListObjectToTuple = (obj) => {
  const {id, registration, pickedUpFlight, type, faaCategory, aircraftDesignGroup, operatorDetails, basedIcaoList, isArchived, iconType} = obj;
  return [id, registration, pickedUpFlight, type, faaCategory, aircraftDesignGroup, operatorDetails, basedIcaoList, isArchived, iconType] 
}
