import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {Object} ScheduledFlightsState
 * @property {Operation[]} flights - Contains list of operations which indicates flight status
 * @property {boolean} loading - Is list loading
 */
const initialState = {
  list: [],
  metaArr: { today: null, yesterday: null, month: null, year: null },
  metaDep: { today: null, yesterday: null, month: null, year: null },
  loading: true,
};

export const scheduledFlightsSlice = createSlice({
  name: 'scheduledFlights',
  initialState,
  reducers: {
    updateScheduledFlights(state, action) {
      const { scheduledFlights = [] } = action.payload;

      if (typeof state.metaArr.today === 'number') {
        const currentFl = state.list.filter((fl) => fl.direction === 'A');
        const newFl = scheduledFlights.filter((fl) => fl.direction === 'A');
        const arrDiff = Math.max(newFl.length - currentFl.length, 0);

        state.metaArr.today += arrDiff;
        state.metaArr.month += arrDiff;
        state.metaArr.year += arrDiff;
      }

      if (typeof state.metaDep.today === 'number') {
        const currentFl = state.list.filter((fl) => fl.direction === 'D');
        const newFl = scheduledFlights.filter((fl) => fl.direction === 'D');
        const arrDiff = Math.max(newFl.length - currentFl.length, 0);

        state.metaDep.today += arrDiff;
        state.metaDep.month += arrDiff;
        state.metaDep.year += arrDiff;
      }

      state.list = scheduledFlights;
      state.loading = false;
    },
    updateScheduledFlightsMeta(state, action) {
      const { scheduledFlightsMetaArr, scheduledFlightsMetaDep } = action.payload;
      state.metaArr = scheduledFlightsMetaArr;
      state.metaDep = scheduledFlightsMetaDep;
    },
  },
});

export default scheduledFlightsSlice.reducer;

export const { updateScheduledFlights, updateScheduledFlightsMeta } = scheduledFlightsSlice.actions;
