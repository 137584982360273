export const TERRAIN = 'google.terrain';
export const ROADMAP = 'google.roadmap';
export const SATELLITE = 'google.satellite';
export const HYBRID = 'google.hybrid';
export const OSM = 'OSM';
export const DARK = 'Dark';
export const IFRL = 'IFRL';
export const IFRH = 'IFRH';
export const VFR = 'VFR';

export const baseLayersLabels = {
  [TERRAIN]: 'Terrain',
  [ROADMAP]: 'Roadmap',
  [SATELLITE]: 'Satellite',
  [HYBRID]: 'Hybrid',
  [OSM]: 'OpenStreetMap',
  [DARK]: 'ATC',
  [IFRL]: 'IFR Low route',
  [IFRH]: 'IFR High route',
  [VFR]: 'VFR',
};
export const baseLayersToGoogleMutantType = {
  [TERRAIN]: 'terrain',
  [ROADMAP]: 'roadmap',
  [SATELLITE]: 'satellite',
  [HYBRID]: 'hybrid',
};
export const baseLayers = [TERRAIN, ROADMAP, SATELLITE, HYBRID, OSM, DARK, VFR, IFRL, IFRH];

// ICONS
export const ICON_PLANE = 'plane';
export const ICON_ATC = 'atc';
export const mapIcons = [ICON_PLANE, ICON_ATC];
export const mapIconsLabels = {
  [ICON_PLANE]: 'Standard',
  [ICON_ATC]: 'ATC',
};

// TAGS
export const TAG_STANDARD = 'tag_standard';
export const TAG_BASIC = 'tag_basic';
export const TAG_ATC = 'tag_atc';
export const TAG_NONE = 'tag_none';
export const tagTypes = [TAG_STANDARD, TAG_BASIC, TAG_ATC, TAG_NONE];
export const tagTypeToLabel = {
  [TAG_STANDARD]: 'Standard',
  [TAG_BASIC]: 'Basic',
  [TAG_ATC]: 'ATC',
  [TAG_NONE]: 'None',
};

// WEATHER LAYERS
export const WEATHER_BREF_QCD = 'BREF.QCD';
export const WEATHER_BREF_RAW = 'BREF.RAW';
export const WEATHER_CREF_RAW = 'CREF.RAW';
export const WEATHER_CREF_QCD = 'CREF.QCD';
export const WEATHER_PCNP_TYP = 'PCNP.TYP';
export const WEATHER_NEET_V18 = 'NEET.V18';

export const weatherRadarLayersDetails = {
  [WEATHER_BREF_QCD]: {
    type: WEATHER_BREF_QCD,
    label: 'QCd Base Reflectivity',
    title:
      'A display of echo intensity measured in dBZ (with ground clutter / non-precipitation echoes removed). Scientists use these products to detect precipitation, evaluate storm structure, locate boundaries, and determine hail potential. Data values are actual reflectivity values (uses the 0.5 degree elevation cut).',
    url: 'https://opengeo.ncep.noaa.gov/geoserver/conus/conus_bref_qcd/ows',
    layers: 'conus_bref_qcd',
  },
  [WEATHER_CREF_QCD]: {
    type: WEATHER_CREF_QCD,
    title:
      'Composite Reflectivity is a display of maximum reflectivity for the total volume within the range of the radar. These products reveal the highest reflectivities in all echoes, examine storm structure features, and determine the intensity of storms. With ground clutter / non-precipitation echoes removed.',
    url: 'https://opengeo.ncep.noaa.gov/geoserver/conus/conus_cref_qcd/ows',
    layers: 'conus_cref_qcd',
    label: 'QCd Composite Reflectivity',
  },
  [WEATHER_PCNP_TYP]: {
    type: WEATHER_PCNP_TYP,
    title:
      'Algorithm which classifies radar echoes into one of seven categories: 1) warm stratiform rain, 2) cool stratiform rain, 3) convective rain, 4) tropical/stratiform rain mix, 5) tropical/convective rain mix, 6) hail, and 7) snow. The primary use of the SPT is for automatic selection of Z-R relationships and for computing Surface Precipitation Rates (SPRs) from the Seamless Hybrid Scan Reflectivity (SHSR) mosaic. (TC: Tropical Convective  TS: Tropical Stratiform  CS: Cool Stratiform  H: Hail  C: Convective  S: Snow  WS: Warm Stratiform)',
    url: 'https://opengeo.ncep.noaa.gov/geoserver/conus/conus_pcpn_typ/ows',
    layers: 'conus_pcpn_typ',
    label: 'Precipitation Type',
  },
  [WEATHER_NEET_V18]: {
    type: WEATHER_NEET_V18,
    title:
      'An image of the echo top heights color-coded in user-defined increments. Scientists use this product for a quick estimation of the most intense convection and higher echo tops, as an aid in identification of storm structure features, and for pilot briefing purposes.',
    url: 'https://opengeo.ncep.noaa.gov/geoserver/conus/conus_neet_v18/ows',
    layers: 'conus_neet_v18',
    label: 'Echo Tops',
  },
};

export const weatherRadarLayerDetailsList = Object.values(weatherRadarLayersDetails);
